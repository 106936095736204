@import "node_modules/tachyons-sass/tachyons.scss";
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,400i,600");

// COLORS
.light-moon-gray {
  background-color: #fafafa;
}
.brand {
  color: #00baff;
}
.bg-brand {
  background-color: #00baff;
}
.hover-brand:hover {
  color: #00baff;
}
.hover-brand:focus {
  color: #00baff;
}
.hover-bg-brand:hover {
  background-color: #00baff;
}
.hover-bg-brand:focus {
  background-color: #00baff;
}
.b--brand {
  border-color: #00baff;
}

// FONTS
.brand-font {
  font-family: "Titillium Web", "Helvetica Neue", sans-serif;
}

// Site wide styles
.scroll-fix {
  @extend .pt5;
  @extend .nt5;
}

body {
  @extend .bg-near-white;
  @extend .brand-font;
}

// Blog Post
.post {
  @extend .f4;

  header h1 {
    @extend .mv3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend .mt5;
  }

  h1 {
    @extend .f1;
  }
  h2 {
    @extend .f2;
  }
  h3 {
    @extend .f3;
  }
  h4 {
    @extend .f4;
  }
  h5 {
    @extend .f5;
  }
  h6 {
    @extend .f6;
  }

  p {
    @extend .pv0;
    @extend .lh-copy;
  }

  & div > ul,
  & div > ol {
    @extend .nl3;
  }

  ul,
  ol {
    @extend .mt0;

    li {
      @extend .ml2;
      @extend .pl2;
      @extend .mt2;
      @extend .lh-copy;
    }
  }

  a {
    @extend .dark-gray;
    @extend .underline;
    @extend .hover-brand;
  }

  figure {
    @extend .tc;
    @extend .center;
  }

  table {
    @extend .w-100;
    @extend .mw8;
    @extend .f6;
    @extend .center;

    thead tr th {
      @extend .tl;
      @extend .pa3;
      @extend .b;
      @extend .bg-black;
      @extend .white;
    }
    tbody {
      @extend .lh-copy;
    }
    tr {
      @extend .striped--near-white;

      td {
        @extend .pa3;
      }
    }
  }

  blockquote,
  code {
    @extend .db;
    @extend .pa3;
    @extend .pa4-ns;
    @extend .bg-light-gray;
  }
  code {
    white-space: pre-wrap;
    @extend .f4;
  }
  blockquote {
    @extend .mh0;
    @extend .bl;
    @extend .bw2;
    @extend .b--brand;

    cite {
      @extend .ttu;
      @extend .tracked;
      @extend .fs-normal;
      @extend .f5;
      @extend .b;
    }
  }
}
